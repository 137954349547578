import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import OpenMapLeaf from '@modules/open-street-map-field'



export default class ProtectedTreePermit extends React.Component {
  resource = '/protected_tree_permits'
  form_key = 'protected_tree_permit'

  isMultiple = /multiple/.test(this.props.location.pathname)

  defaults = {
    trees_num: 1
  }

  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        multiple_trees: this.isMultiple
      },
    },
  }

  // Delete lines as needed
  // fee           = 99
  free          = false
  postpaid      = true
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  fields = form => [
    {
      name: 'i_am_applicant',
      label: 'I am applying for myself',
      type: 'select:bool'
    },
    {
      name: 'f_name',
      label: 'Applicant first name',
      hide: form.i_am_applicant,
    },
    {
      name: 'm_name',
      label: 'Applicant middle name',
      hide: form.i_am_applicant,
      required: false,
    },
    {
      name: 'l_name',
      label: 'Applicant last name',
      hide: form.i_am_applicant,
    },
    {
      name: 'address',
    },
    {
      name: 'po_box',
      label: 'P.O. Box'
    },
    {
      name: 'island',
      options: islands
    },
    {
      name: 'telephone',
      type: 'phone'
    },
    {
      name: 'fax',
      type: 'phone',
    },
    {
      name: 'location',
      label: 'Location or site of the tree'
    },
   {
    name: 'gps_coordinates',
    // Customer View
    heading:
             (<div>
                 <center>
                     <h5>Pinpoint Location on Map</h5>
                     <OpenMapLeaf form={this}/>
                 </center>
             </div>),
    // Officer View
    view: {
      element: (value, form) => form.latitude ? (
        <OpenMapLeaf form={form} showMap={true} />
      ) : null },
    save: false,
  },
    {
      name: 'valid_permit',
      label: 'Is the site covered by a valid permit for conduct of excavation or landfil?',
      type: 'select:bool'
    },
    {
      name: 'is_covered',
      label: 'Please provide the permit number',
      type: 'integer',
      hide: form.valid_permit != true
    },
    {
      name: 'not_covered',
      label: 'What is the reason for the removal of the trees?',
      type: 'textarea',
      hint: 'Example: Description of Operation: Land clearing, construction, removal/transplant/destruction of a tree for construction purposes.',
      hide: form.valid_permit != false
    },
    {
      name: 'land_clearing',
      label: 'Please provide details on the land clearing',
      type: 'textarea'
    },
    {
      name: 'size',
      type: 'decimal',
      label: 'Provide the size of the area (in acres)',
      hint: 'Eg. 0.3 acres'
    },
    {
      name: 'tree_names',
      label: 'Common and Scientific name of protected tree(s)',
      rowLabel: 'Tree',
      max: 1,
      fields: [ {
                  name: 'common_name',
                  label: 'Name/Type'
                },
                {
                  name: 'scientific_name'
                },
                {
                  name: 'age',
                  is: 'integer',
                  //required: false
                },
                {
                  name: 'size',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'height',
                  hint: 'Include metric of measurement used',
                  //required: false
                },
                {
                  name: 'quantity',
                  label: 'Number of Trees',
                }
              ]
    },
    {
      name: 'trees_num',
      label: 'Number of protected trees under the Act',
      type: 'integer',
      disabled: true,
    },
   /* {
      name: 'tree_measurments',
      label: 'Age/size/height of the tree(s)',
      rowLabel: 'Age/size/height of Tree',
      max: 1,
      fields: [
        {
          name: 'tree_name'
        },
        {
          name: 'age',
          is: 'integer',
          required: false
        },
        {
          name: 'size',
          required: false
        },
        {
          name: 'height',
          required: false
        }
      ]
    },*/
    {
      name: 'equipment_type_list',
      label: 'Type of equipment used for harvesting protected tree(s)',
      columns: ['equipment'],
      format: v => v.equipment
    },
    {
      name: 'proposed_method',
      label: 'Proposed method of harvesting protected tree(s)',
      type: 'textarea'
    },
    {
      name: 'invasive_species_removal',
      label: 'Proposed plan for Invasive species removal',
      type: 'textarea'
    },
    {
      name: 'restorative_plan_upload',
      label: 'Planned restorative and reinstatement plans and future land use plans for the site',
      hint: 'Mitigation/Reinforestation/Landscape plan',
      key: 'image.1',
      type: 'file:pdf'
    },
    {
      name: 'invasive_species_upload',
      label: 'Invasive species removal plan',
      key: 'images.2',
      type: 'file:pdf'
    },
    {
      name: 'authorization_letter_upload',
      label: 'Authorization Letter',
      key: 'images.3',
      type: 'file:pdf'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
