import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import islands from '@constants/islands'
import OpenMapLeaf from '@modules/open-street-map-field'


export default class DerelictVehicle extends React.Component {
  resource = '/derelict_vehicles'
  form_key = 'derelict_vehicle'

  coordinates = null

  defaults = {
    // latitude: this.coordinates[0],
    // longitude: this.coordinates[1],
  }

  // Delete lines as needed
  // fee           = 99
  free          = true
  postpaid      = false
  skip_location = true
  hide_buttons  = false
  custom_actions = []

  // Allow map component selected coordinates to translate to form latitude and longitude fields
  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }


  foremost = null


  fields = form => [
    "::Vehicle Information",
    {
      name: 'vehicle_make',
      options: [
            "Abarth",
            "Alfa Romeo",
            "Aston Martin",
            "Audi",
            "Bentley",
            "BMW",
            "Bugatti",
            "Cadillac",
            "Chevrolet",
            "Chrysler",
            "Citroën",
            "Dacia",
            "Daewoo",
            "Daihatsu",
            "Dodge",
            "Donkervoort",
            "DS",
            "Ferrari",
            "Fiat",
            "Fisker",
            "Ford",
            "Honda",
            "Hummer",
            "Hyundai",
            "Infiniti",
            "Iveco",
            "Jaguar",
            "Jeep",
            "Kia",
            "KTM",
            "Lada",
            "Lamborghini",
            "Lancia",
            "Land Rover",
            "Landwind",
            "Lexus",
            "Lotus",
            "Maserati",
            "Maybach",
            "Mazda",
            "McLaren",
            "Mercedes-Benz",
            "MG",
            "Mini",
            "Mitsubishi",
            "Morgan",
            "Nissan",
            "Opel",
            "Peugeot",
            "Porsche",
            "Renault",
            "Rolls-Royce",
            "Rover",
            "Saab",
            "Seat",
            "Skoda",
            "Smart",
            "SsangYong",
            "Subaru",
            "Suzuki",
            "Tesla",
            "Toyota",
            "Volkswagen",
            "Volvo"
      ]
    },
    {
      name: 'vehicle_model'
    },
    {
      name: 'vehicle_color'
    },
    {
      name: 'license_number',
      label: 'Vehicle License Plate Number',
      hint: 'if available',
      required: false
    },
    {
      name: 'vehicle_id',
      label: 'Vehicle Identification Number(VIN)',
      hint: 'if available',
      required: false
    },

    "::Location of Derelict Vehicle",
    {
      name: 'island',
      options: islands
    },
    {
      name: 'street_address'
    },
    {
      name: 'latitude',
      save: true,
      hide: true,
      // Set view value as string to show negative numbers
      view: {
        value: v => `${v} lat`
      }
    },
    {
      name: 'longitude',
      save: true,
      hide: true,
      // Set view value as string to show negative numbers
      view: {
        value: v => `${v} lng`
      }
    },

    // Include Map Component
    {
      name: 'gps_coordinates',
      // Customer View
      heading: 
               (<div>
                   <center>
                       <h5>Pinpoint Location on Map</h5>
                       <OpenMapLeaf form={this}/>
                   </center>
               </div>),
      // Officer View
      view: {
        element: (value, form) => form.latitude ? (
          <OpenMapLeaf form={form} showMap={true} />
        ) : null },
      save: false,
    },

    "::Condition of the Vehicle",
    {
      name: 'vehicle_damaged',
      label:'Is the vehicle damaged?',
      type: 'select:bool'
    },
    {
      name: 'vehicle_abandoned',
      label: 'Does the vehicle appear to have been abandoned?',
      type: 'select:bool'
    },
    {
      name: 'safety_hazard',
      label: 'Is the vehicle creating a safety hazard?',
      type: 'select:bool'
    },

    "::Duration",
    {
      name: 'how_long',
      label: 'How long has the vehicle been at this location?',
      options: [
        'Less than a week',
        '1 to 2 weeks',
        '3 to 4 weeks',
        'More than a month'
      ]
    },

    "::Additional Information",
    {
      name: 'additional_info',
      label: 'Additional Comments or Observations',
      type: 'textarea',
      required: false
    },

    "::Uploads",
    {
      name: 'vehicle_photo_upload',
      label: 'Upload a Photo of the Vehicle',
      key: 'image.0',
      type: 'file:image'
    },
    {
      name: 'extra_photo_upload',
      label: 'Upload an additional Photo of the Vehicle',
      key: 'image.1',
      type: 'file:image'
    }
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}
