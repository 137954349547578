import React, { useEffect, useRef, useState } from 'react'
import leaf from 'leaflet'


/* TEMPLATE FOR FORM

  // Allow map component selected coordinates to translate to form latitude and longitude fields

  validate = async values => {
    const update = {}

    if(this.coordinates) {
      update.latitude = this.coordinates[0]
      update.longitude = this.coordinates[1]
    }

    return update
  }

  // OpenMapLeaf Component hardcoded to work with the latitude and longitude named fields exactly as spelt

  fields = form => [
    {
      name: 'latitude',
      save: true,
      hide: true,
      // Set view value as string to show negative numbers
      view: {
        value: v => `${v} lat`
      }
    },
    {
      name: 'longitude',
      save: true,
      hide: true,
      // Set view value as string to show negative numbers
      view: {
        value: v => `${v} lng`
      }
    },

    // Include Map Component
    {
      name: 'gps_coordinates',
      // Customer View
      heading: 
                (<div>
                    <center>
                        <h5>Pinpoint Location on Map</h5>
                        <OpenMapLeaf form={this}/>
                    </center>
                </div>),
      // Officer View
      view: {
        element: (value, form) => form.latitude ? (
          <OpenMapLeaf form={form} showMap={true} />
        ) : null },
      save: false,
    },
  ]
*/


const OpenMapLeaf = ({ form, showMap = false }) => {
  // Used for showMap render
  const openmapURL = `https://www.openstreetmap.org/?mlat=${form.latitude}&mlon=${form.longitude}#map=17/${form.latitude}/${form.longitude}`;
  // console.log(form, openmapURL);

  const containerStyle = {
    width: '400px',
    height: '400px',
    marginBottom: '20px', // Add margin at the bottom
    zIndex: '1',
  };

  const defaultCenter = {center: [25.0343, -77.3963], zoom: 14}; // Latitude , Longitude

  // Navigator Geolocation object options
  const options = {
    enableHighAccuracy: true,
    timeout: 25000,
    maximumAge: 0,
  };

  const [currentPosition, setCurrentPosition] = useState(defaultCenter) // []
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const mapRef = useRef(null);
  // const markerRef = useRef(null);

  // Navigator Gelocation getCurrentPosition() callbacks, Success and Error
  const success = (pos) => {
    const crd = pos.coords;
  
    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    // console.log(`More or less ${crd.accuracy} meters.`);

    setCurrentPosition({center: [crd.latitude, crd.longitude], zoom: 14})
  }
  
  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  // Generate Leaflet map using OpenStreetMap
  useEffect(() => {
    // Generate a map with default co-ordinates on component load
    if (!showMap) {
      mapRef.current = leaf.map('Map', currentPosition);
      leaf.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(mapRef.current);

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, options)
      } else {
        console.log("Geolocation not supported");
      }
    }
  }, [])


  useEffect(() => {
    // Set map default co-ordinates to current user position of Navigator geolocation was allowed
    if (!showMap) mapRef.current.setView(currentPosition.center, 14);
  }, [currentPosition])

  useEffect(() => {
    const onMapClick = (e) => {
      var popup = leaf.popup();
      // console.log(e.latlng);
      setLatitude(e.latlng.lat);
      setLongitude(e.latlng.lng);

      popup
          .setLatLng(e.latlng)
          .setContent(e.latlng.toString())
          .openOn(mapRef.current);
    }

    if (mapRef.current) {
      mapRef.current.on('click', onMapClick);
    }
  }, [mapRef.current])

  useEffect(() => {
    if (form) {
      form.coordinates = [latitude, longitude];
      // console.log(form);
    }
  }, [latitude, longitude])

  // Return an OpenMap URL if showMap prop is true otherwise return map widget
  return (
    showMap ? 
    <a href={openmapURL} target='_blank' rel='noreferrer'>(Click here for Map Location)</a>
    : 
    <div id='Map' ref={mapRef} style={containerStyle}/>
  )
}

export default OpenMapLeaf;
