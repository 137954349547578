import React from 'react'

import Wizard from '@modules/form-wizard'
import SERVICE_PROPS from '@helpers/extract-service-props'
import { BUSINESS_FIELDS } from '@modules/form-wizard/utils'
import islands from '@constants/islands'
import OpenMapLeaf from '@modules/open-street-map-field'

export default class TimberLicense extends React.Component {
  resource = '/timber_licenses'
  form_key = 'timber_license'
  defaults = {}




  isLargeScale = /large-scale/.test(this.props.location.pathname)


  read_params = {
    admin: true,
    label: ' ',
    default: 'Default',
    values: {
      'Default': {
        high_forest_acreage: this.isLargeScale
      },
    },
  }
  // Delete lines as needed
  // fee           = 99
  free          = false
  postpaid      = false
  skip_location = true
  pay_on_approval = true
  hide_buttons  = false
  custom_actions = []

  validate = null

  foremost = null

  /*
  t.string :form_num
t.string :payment_amount
t.string :payment_invoice
t.string :payment_status
t.string :application_status
t.string :application_decision, default: 'pending'
t.boolean :acceptance, default: false
t.boolean :declare, default: false
t.string :pickup_location
t.string :pickup_sub_location
t.belongs_to :user, foreign_key: true
t.belongs_to :delivery, foreign_key: true, null: true
t.belongs_to :officer, foreign_key: { to_table: :users }, null: true
t.belongs_to :proxy, foreign_key: { to_table: :users }, null: true
t.string :delivery_status
t.date :app_status_change_date
t.string :ip_address
t.boolean :requested_proxy, default: false

# DIR Verification Info
t.string :client_type
t.string :company_name
t.string :company_email
t.string :bl_number
t.string :tin_number
t.string :business_nib
t.string :street_address
t.string :po_box
t.string :phone_number
t.string :trading_name
t.string :company_type

t.string :license_numb
t.date :license_expiry_date
t.date :license_approved_date
t.date :license_approval_date
t.attachment :pdf_upload

t.string :forest_name
t.string :forest_acreage
t.string :forest_island
t.string :forest_city
t.string :forest_street_address
t.decimal :longitude, precision: 10, scale: 6
t.decimal :latitude, precision: 10, scale: 6
t.string :forest_settlement
t.string :timber_specifics_list, array: true, default: []
t.integer :removal_quantity
t.string :timber_use
t.string :equipment_used_list, array: true, default: []
t.boolean :power_saw_permit, default: false
t.string :saw_permit_numb
t.date :saw_permit_date
t.date :commencement_date
t.date :completion_date

t.attachment :impact_assessment_upload
t.attachment :management_plan_upload
t.attachment :business_plan_upload
  */

  fields = form => [
    ...BUSINESS_FIELDS(form),
    {
      name: 'renewal',
      save: true,
      hide: true,
    },
    '::Forest Estate Details',
    {
      name:'forest_name',
      label:'Name of forest estate',
    },
    {
      name:'forest_acreage',
      label:'Approximate acreage of forest estate',
      type: 'integer',
    },
    {
      name:'forest_island',
      label:'Island',
      options: islands,
    },
    // {
    //   name:'forest_city',
    //   label:'',
    // },
    {
      name:'forest_street_address',
      label:'Address',
    },
    {
      name: 'gps_coordinates',
      // Customer View
      heading:
               (<div>
                   <center>
                       <h5>Pinpoint Location on Map</h5>
                       <OpenMapLeaf form={this}/>
                   </center>
               </div>),
      // Officer View
      view: {
        element: (value, form) => form.latitude ? (
          <OpenMapLeaf form={form} showMap={true} />
        ) : null },
      save: false,
    },
    {
      name:'forest_settlement',
      label:'Nearest Settlement',
    },

    '::Specific Information',
    {
      name: 'timber_specifics_list',
      label: 'Specifics of timber or non-timber produce to be removed',
      // hint: 'Applicable for instrument class only',
      columns: ['timber', 'non-timber'],
      format: v => v.timber,
    },
    {
      name:'removal_quantity',
      label:'Quantity of produce to be removed',
    },
    {
      name:'timber_use',
      label:'Use of timber or non-timber forest produce',
    },
    {
      name:'equipment_used_list',
      label:'Equipment to be used to harvest produce',
      columns: ['equipment'],
      format: v => v.equipment,
    },
    {
      name:'power_saw_permit',
      type: 'select:bool',
      label:'Are you the holder of a valid permit to use and/transport a Power Saw?',
    },
    {
      name:'saw_permit_numb',
      label:'Permit No.',
      // type: 'integer',
      hide: !form.power_saw_permit,
    },
    {
      name:'saw_permit_date',
      label:'Date of Issue',
      type:'date',
      hide: !form.power_saw_permit,
    },
    {
      name:'commencement_date',
      label:'Proposed date of commencement of removal',
      type:'date',
    },
    {
      name:'completion_date',
      label:'Proposed date of completion',
      type:'date',
    },
    {
      name:'impact_assessment_upload',
      key: 'image.1',
      // label:'',
      type: 'file:all',
    },
    {
      name:'management_plan_upload',
      key: 'image.2',
      // label:'',
      type: 'file:all',
    },
    {
      name:'business_plan_upload',
      key: 'image.3',
      // label:'',
      type: 'file:all',
    },
  ]

  render = () => <Wizard {...SERVICE_PROPS(this)} />
}


